import React from "react";
import { graphql } from "gatsby";
import SocialContainer from "../components/SocialContainer";
import SEO from "../components/SEO";
import {
  AnimatedRandomX,
  FadeIn,
  AnimatedText,
} from "../components/Animations";
import ContactForm from "../components/ContactForm";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

const Contact = (props: any) => (
  <>
    <SEO {...props.data.prismicSeo.data} />
    <div>
      <div className="hero-contact">
        <div className="container-hero-contact">
          <div className="div-block-107">
            <div className="wrapper-visit">
              <h1 className="heading large white">
                <AnimatedText>Glad to see you here.</AnimatedText>
              </h1>
              <p className="p2 large white-copy">
                <FadeIn duration={1000}>
                  We&apos;re happy that We caught your attention. <br />
                </FadeIn>
              </p>
              <div className="div-block-45" />
              <p className="p2 large white-copy">
                <FadeIn duration={2000}>
                  Do you have an idea you want to bring to life? <br />
                  Do you need a remote team to help you craft your project?
                  <br />
                  Are you looking for help to make the next deadline?
                </FadeIn>
              </p>
              <div className="div-block-45" />
              <p className="p2 large white-copy">
                <FadeIn duration={3000}>
                  If you want to see how we can help just ask us, it&apos;s as
                  simple as that.
                </FadeIn>
              </p>
            </div>
            <div className="back-contact" />
            <RandomBlockX className="div-block-105" />
            <RandomBlockX className="div-block-109" />
          </div>
          <div className="contact-info">
            <div className="contact-info-details say-hi">
              <p className="cap-2">Say Hi</p>
              <a href="mailto:hi@mosano.eu" className="p2">
                hi@mosano.eu
              </a>
            </div>
            <div className="contact-info-details">
              <p className="cap-2">Better Call Nelson</p>
              <a href="tel:+351300003520" className="p2">
                +351 300 003 520
              </a>
              <p className="call-cost__mosano-info p2">
                Phone call will be charged at your local rate
              </p>
            </div>
            <div className="contact-info-details">
              <p className="cap-2">Our Office </p>
              <div className="p2 large">
                Rua D. João I, nº 256
                <br />
                4450-162 Matosinhos, <br />
                Portugal
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="application-text1 service__contact-form" />
      <ContactForm TEMPLATE_ID="Website_Lead" type="contact" />
    </div>
  </>
);

export default Contact;

export const pageQuery = graphql`
  query ContactQuery {
    prismicSeo(data: { name: { eq: "contact" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;
